<template>
  <div class="proposal__wrap">
    <div class="section shadow filter__wrap">
      <div>
        <el-button type="text" @click="$router.push('/platform')">人才币规则</el-button>
        <el-button type="primary">佣金规则</el-button>
      </div>
    </div>
    <!-- 表格 -->
    <div class="table__wrap">
      <div class="section shadow" style="padding: 100px 50px;">
        <el-form
          ref='elFormDom'
          label-width='140px'
          label-position='right'
          :model='form.data'
          :rules='form.rules'>
          <el-row :gutter="40">
            <el-col :span="8">
              <el-form-item prop="school_rate" label="学校代理佣金比例">
                <el-input  type="age" v-model.number="form.data.school_rate" placeholder="请输入佣金比例">
                  <span slot="suffix">%</span>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item prop="agent_rate" label="拉新代理佣金比例">
                <el-input  type="age" v-model.number="form.data.agent_rate" placeholder="请输入佣金比例">
                  <span slot="suffix">%</span>
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <div class="section shadow" style="padding:20px;margin-top:40px;">
        <el-row type="flex" justify="center">
          <el-button @click="reset">重置</el-button>
          <el-button type="primary" :loading="form.loading" :disabled="form.loading" @click="confirm">确定</el-button>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
import VTable from '@/components/VTable';
export default {
  name: 'AgentList',
  components: {
    VTable,
  },
  data() {
    return {
      form: {
        loading: false,
        data: {
          school_rate: '',
          agent_rate: '',
        },
        rules: {
          school_rate: [
            { required: true, message: '佣金比例不能为空'},
            { type: 'number', message: '佣金比例只能输入整数'}
          ],
          agent_rate: [
            { required: true, message: '佣金比例不能为空'},
            { type: 'number', message: '佣金比例只能输入整数'}
          ],
        }
      },
      
    }
  },
  created() {
    this.getTable();
  },
  methods: {
    getTable() {
      this.$http.get('admin/rule/getRate', {params: {}}).then(res => {
        if(res.code === 1) {
          this.form.data = res.data
          this.form.data.school_rate = Number(res.data.school_rate)
          this.form.data.agent_rate = Number(res.data.agent_rate)
        } else {
          this.$message.error(res.msg);
        }
      })
    },
    confirm() {
      this.$refs.elFormDom.validate(valid => {
        if (valid) {
          this.form.loading = true;
          this.$http.post('admin/rule/setRate', this.form.data).then(res => {
            if(res.code === 1) {
              this.$message.success('设置比例成功')
            } else {
              this.$message.error(res.msg)
            }
          }).finally(() => {
            this.form.loading = false;
          })
        }
      })
    },
    reset() {
      this.$refs.elFormDom.resetFields()
    }
  }
}
</script>

<style scoped lang="scss">
  .flex {
    display: flex;
  }
  .proposal__wrap {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 20px;

    .filter__wrap {
      padding: 10px;
      margin-bottom: 20px;
      display: flex;
      justify-content: space-between;
    }

    .table__wrap {
      flex: 1;
      padding: 0;
      box-sizing: border-box;
    }
  }
</style>